import React from "react";
import workingChild from "../../assets/images/workingChild.svg";
import headerBackGround from "../../assets/images/headerBackGround.svg";
import logo from "../../assets/images/IOCLogo.svg";
import blackDottedLeft from "../../assets/images/blackDottedLeft.svg";
import blackDottedRight from "../../assets/images/blackDottedRight.svg";
import hiring from "../../assets/images/hiring.svg";
import { Button } from "semantic-ui-react";
import Facebooklogo from "assets/images/fb.webp";
import instalogo from "assets/images/insta.webp";
import linkedinlogo from "assets/images/linkedin.webp";
import styles from "./scss/hiring.module.scss";

class HiringLayout extends React.Component {
  render() {
    const scrolling = () => {
      const element = document.getElementById("FooterContent");
      element.scrollIntoView({ behavior: "smooth" });
    };

    return (
      <>
        <div className={styles.leftImage}>
          <img className={styles.image2} src={blackDottedLeft} alt="logo" />
        </div>
        <img className={styles.image3} src={blackDottedRight} alt="logo" />
        <div>
          <div className={styles.logoIOC}>
            <img className={styles.logoImg} src={logo} alt="logo" />
          </div>
          <div className={styles.headerbg}>
            <img
              className={styles.bluishgreenbg}
              src={headerBackGround}
              alt="logo"
            />
          </div>
          <div className={styles.subHeader}>
            <div className={styles.subheaderLeft}>
              <div className={styles.subheaderContent}>
                <p>
                  THE ADULTS ARE CONSTRUCTING
                  <span className={styles.spanText}> EXCITING THINGS </span>
                  FOR US
                </p>
              </div>
              <div className={styles.buttonDiv}>
                <Button
                  onClick={() => scrolling()}
                  className={styles.button}
                  color="orange"
                  content="Join our team"
                />
              </div>
            </div>
            <div className={styles.headerRight}>
              <img className={styles.image1} src={workingChild} alt="logo" />
            </div>
          </div>
          <div className={styles.contentPara}>
            <div className={styles.para}>
              <p>
                <b>Image Of A Child</b> is an organisation that aims to provide
                experiential learning to the 22 nd century learners, by curating
                modules that encompass pedagogies with artificial intelligence
                (AI) to meet the high standards of the contemporary learning
                environment.
              </p>
            </div>
            <div className={styles.para}>
              <p>
                Our platform is inspired by teaching methods like The Reggio
                Emilia approach,Waldorf, Montessori and The Bank Street method.
                While ensuring learning goals are met through continuous ongoing
                assessments and documentation of learner
              </p>
            </div>
            <div className={styles.para}>
              <p>
                Keeping The Image of A Child at the centre – our taxonomies are
                designed to aid our learners enhance their understanding by
                building personal social and emotional skills, problem-solving
                and reasoning abilities, creative thinking and communication
                skills through experiential learning on our interactive AI
                Platforms. Our aim is to help nurture 22nd century citizens and
                leaders,with a cutting-edge curriculum to cater to the formative
                period of early years in a user-friendly platform, which
                supports early learning while catering to diverse intelligences.
              </p>
            </div>
          </div>
          <div className={styles.hiringImg}>
            <img className={styles.image4} src={hiring} alt="logo" />
          </div>
        </div>
        <div>
          <h3 className={styles.hiringH3}>
            - Recruiting EARLY CHILDHOOD EDUCATION FACILITATORS AND CURRICULAM
            DEVELOPERS -
          </h3>
          <div className={styles.HirringLayout}>
            <div className={styles.HirringContent}>
              <p>
                <b>My little eyes sparkle </b>
                with wonder and curiosity
                <br />
                I discover my planet that is new and different
                <br />
                I discover my environments with joy and awe
                <br />
                I capture the colours, the movements, the people
                <br />I capture the faces, the actions, the characters
              </p>
              <p>
                <b>My little ears are attuned</b> to surrounding sounds
                <br />
                I’m drawn to the languages of animals and humans
                <br />
                I’m drawn to the sounds of music and laughter
                <br />
                I listen to the noises I create and experiment with
                <br />I listen to the racket and melody of my world
              </p>
              <p>
                <b>My little hands explore</b> my connect with nature
                <br />
                I like to feel mud, grass, flowers and sand
                <br />
                I like to feel objects with different textures
                <br />
                I love to play with pebbles, leaves and water
                <br />I love to play with crayons, paints, and chalk
              </p>
              <p>
                <b>My little mind excites</b> in new ways of thinking
                <br />
                I learn from books, visuals, and voices
                <br />
                I learn from my mistakes and triumphs
                <br />
                I gain through the forms of input I receive
                <br />I gain through mixed experiences and ideas
              </p>
              <p>
                <b>My little heart needs love</b> and emotional security
                <br />
                I express my moods, feelings, and behaviours
                <br />
                I express my own individual needs and wishes
                <br />
                I feel happy with warm hugs and words of cheer
                <br />I feel safe with tender care and personal attention
              </p>
              <p>
                <b>My little dreams</b> are what I want to always believe in
                <br />
                I wish to live out the best version of me
                <br />
                I wish to grow as confident, kind, and smart
                <br />
                I want everyone to see all my talents and gifts
                <br />I want the world to know I have amazing skills
              </p>
              <p>
                <b>My little goals</b> require your expert helping hand
                <br />
                I need to stay grounded in the right roots
                <br />
                I need to be a better human everyday
                <br />
                I must adjust to this fast-changing world
                <br />I must prepare to face the 22nd century
              </p>
              <p>
                <b>My little plea</b> is to bloom bright in my future ahead
                <br />
                Please aid me in staying true to who I am
                <br />
                Please aid me in shaping my unique potential
                <br />
                Will you help me to build a road map to success?
                <br />
                Will you help me to access the knowledge I need?
              </p>
              <span className={styles.content2}>
                <p>
                  My little request is to enthusiasts of Montessori, Waldorf,
                  Reggio,
                  <br /> IB and Play Way:
                </p>
                <p>
                  Can you empower my early learning with a futurist curriculum?
                  <br />
                  Can you empower my family and teachers with the ways I learn
                  best?
                  <br />
                  Could you show me how to have a mind of my own and be
                  different?
                  <br />
                  Could you show me how to surge ahead and achieve great things?
                </p>
              </span>
              <span className={styles.content3}>
                <p>
                  By,
                  <br />
                  The 22nd Century Learners!
                  <br />
                  &ldquo;The Souls Who dwell in the House of Tomorrow.&ldquo;
                </p>
              </span>
              <div id="FooterContent" className={styles.FooterContent}>
                <div className={styles.careerDetails}>
                  <div className={styles.career}>
                    <h1>For Career Opportunities write to </h1>
                  </div>
                  <br></br>
                  <div className={styles.careerBtn}>
                    <Button color="orange" size="huge" circular>
                      contact@imageofachild.ai
                    </Button>
                  </div>
                </div>
                <br></br>
                <div className={styles.contactDetails}>
                  <div className={styles.mobileDetails}>
                    <div className={styles.callBtn}>
                      Call
                      <span className={styles.mobNum}>
                        &nbsp;<a href="tel:9345925381">9345925381</a>
                      </span>
                    </div>
                  </div>
                  <div className={styles.orBtn}>or</div>
                  <div className={styles.iconBtn}>
                    <img src={Facebooklogo} alt="logo"></img>
                    <img src={instalogo} alt="logo"></img>
                    <img src={linkedinlogo} alt="logo"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}></div>
        </div>
      </>
    );
  }
}

export default HiringLayout;
