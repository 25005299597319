import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { integrateSchoolContent } from "./HomeScreenData";
import integrateSchool from "assets/images/home/integrateSchool.webp";
import styles from "./scss/HomeScreen.module.scss";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Integrate = ({ windowWidth }) => {
  const renderIntegrateDesktopView = () => (
    <div className={styles.integrateSchoolWrapper}>
      <div className={styles.sclDiv}>
        <LazyLoadImage src={integrateSchool} />
      </div>
      <div className={styles.sclContentDiv}>
        <h2 className={styles.sclContentHeader}>
          Why Should Schools Integrate IOAC?
        </h2>
        <p>{integrateSchoolContent}</p>
        <div
          className={styles.knowMoreBtn}
          onClick={() => history.push(`/IOAC`)}
        >
          know more
        </div>
      </div>
    </div>
  );

  const renderIntegrateMobileView = () => (
    <div className={styles.sclContentMblWrapper}>
      <h2 className={styles.sclContentHeader}>
        Why Should Schools Integrate IOAC?
      </h2>
      <LazyLoadImage src={integrateSchool} className={styles.sclImg} />
      <p>{integrateSchoolContent}</p>
      <div className={styles.knowMoreMblBtnWrapper}>
        <div
          className={styles.knowMoreMblBtn}
          onClick={() => history.push(`/IOAC`)}
        >
          know more
        </div>
      </div>
    </div>
  );
  return (
    <>
      {windowWidth > mediaBreakpoint.mobile ? (
        <>{renderIntegrateDesktopView()}</>
      ) : (
        <>{renderIntegrateMobileView()}</>
      )}
    </>
  );
};

export default Integrate;
