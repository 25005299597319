import React, { useEffect, useState, useRef } from "react";
import EarleyYears from "../../globals/components/EarlyYears";
import Programs from "./Programs";
// import ReviewSection from "./ReviewSection";
import HomeFrame from "assets/images/home/HomeImageFrame.webp";
import homeDotedImage from "assets/images/home/homeDotedImage.webp";
import mobileDotedImage from "assets/images/home/dotedImageMobile.webp";
import homeFlowerImage from "assets/images/home/homeFlowerImage.svg";
import comingSoon from "assets/images/comingSoon.webp";
import styles from "./scss/HomeScreen.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ioacSpecialContent } from "./HomeScreenData";
import {
  Icon,
  Modal,
  ModalDescription,
  ModalActions,
  Button,
} from "semantic-ui-react";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { useHistory } from "react-router-dom";
import RegistrationForm from "../product/RegistrationForm";
import Features from "./Features";
import Integrate from "./Integrate";

const HomeScreen = () => {
  const history = useHistory();
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [activeItem, setActiveItem] = useState(1);
  const [regModalOpen, setRegModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [activePlatformItem, setPlatformActiveItem] = useState(1);
  const programRef = useRef();

  useEffect(() => {
    document.title = "Home | IOAC ";
    setwindowWidth(window.innerWidth);
  }, []);

  const handleScrollToProgram = () => {
    if (programRef.current) {
      programRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={styles.homeScreenWrapper}>
      <EarleyYears>
        <div className={styles.btn}>
          <div
            className={styles.enquirebtn}
            onClickCapture={() => history.push(`/parents`)}
          >
            Parent
            <Icon name="arrow right" className={styles.arrowIcon} />
          </div>
          <div
            className={styles.enquirebtn}
            onClickCapture={handleScrollToProgram}
          >
            School
            <Icon name="arrow right" className={styles.arrowIcon} />
          </div>
          {/* <div
            className={styles.videobtn}
            onClickCapture={() => setVideoModalOpen(true)}
          >
            Video
            <Icon name="play circle outline" className={styles.playIcon} />
          </div> */}
        </div>
      </EarleyYears>
      <div className={styles.mainContentContainer}>
        <div>
          <LazyLoadImage
            src={homeFlowerImage}
            className={styles.homeFlowerImage}
            alt="homeFlowerImage"
          />
        </div>
        {windowWidth > mediaBreakpoint.mobile ? (
          <picture>
            <img
              src={homeDotedImage}
              className={styles.dotedImage}
              alt="doted"
            />
          </picture>
        ) : (
          <img
            src={mobileDotedImage}
            className={styles.dotedImage}
            alt="dotedImageMobile"
          />
        )}
        <div className={styles.mainContent}>
          <div>
            <LazyLoadImage
              src={HomeFrame}
              className={styles.imageFrame}
              alt="HomeImageFrame"
            />
          </div>
          <div className={styles.specialContent}>
            <h4 className={styles.contentHeader}>What makes IOAC Special??</h4>
            <ol>
              {ioacSpecialContent.map((e) => {
                return <li key={`${e.id}`}>{e.content}</li>;
              })}
            </ol>
          </div>
        </div>
      </div>

      <div className={styles.programs} ref={programRef}>
        <Programs />
      </div>
      <Integrate windowWidth={windowWidth} />

      <Features
        windowWidth={windowWidth}
        activePlatformItem={activePlatformItem}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        setPlatformActiveItem={setPlatformActiveItem}
      />

      {/* <div className={styles.reviewSection}>
        <ReviewSection />
      </div> */}
      {regModalOpen === true && (
        <>
          <Modal open>
            <Modal.Content>
              <RegistrationForm handleRegModal={setRegModalOpen} />
            </Modal.Content>
          </Modal>
        </>
      )}
      {videoModalOpen === true && (
        <>
          <Modal onClose={() => setVideoModalOpen(false)} open size="small">
            <Modal.Content>
              <ModalDescription>
                <div className={styles.comingSoon}>
                  <img src={comingSoon} alt="logo" />
                </div>
              </ModalDescription>
            </Modal.Content>
            <ModalActions>
              <Button color="orange" onClick={() => setVideoModalOpen(false)}>
                Cancel
              </Button>
            </ModalActions>
          </Modal>
        </>
      )}
    </div>
  );
};

export default HomeScreen;
