import React, { useEffect, useState } from "react";
import styles from "./scss/Programs.module.scss";
import ProgramsMobile from "./ProgramsMobile";
import programs from "assets/images/home/programs.webp";
import prenurseryImage from "assets/images/home/prenursery.webp";
import nursery from "assets/images/home/nursery.webp";
import kindergarten1 from "assets/images/home/kindergarten1.webp";
import kindergarten2 from "assets/images/home/kindergarten2.webp";
import grade1 from "assets/images/home/grade1.webp";
import grade2 from "assets/images/home/grade2.webp";
import grade3 from "assets/images/home/grade3.webp";
import programeBg from "assets/images/home/programBG.webp";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { programContent } from "./ProgramsData";
import { useHistory } from "react-router-dom";
const Programs = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const history = useHistory();

  useEffect(() => {
    setwindowWidth(window.innerWidth);
  }, []);

  const handleChange = (data) => {
    history.push("/programsOffered", { state: data });
  };
  const tablet = windowWidth < mediaBreakpoint.bigTablet;
  const viewBox = tablet ? "50 0 1450 1700" : "50 0 1450 1850";
  return (
    <div>
      {windowWidth > mediaBreakpoint.mobile ? (
        <svg
          height={tablet ? "1150" : "1600"}
          width="1250"
          viewBox={viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.programSvg}
        >
          <foreignObject x="50" y="0" height="3000" width="1450">
            <div className={styles.programs}>
              <picture>
                <img
                  src={programeBg}
                  className={styles.programeBg}
                  alt="programe"
                />
              </picture>
              <div className={styles.programeImage}>
                <img src={programs} alt="programs" />
              </div>
              <div className={styles.programsContent}>
                {programContent.map((contentObj, i) => {
                  const imageArr = [
                    prenurseryImage,
                    nursery,
                    kindergarten1,
                    kindergarten2,
                    grade1,
                    grade2,
                    grade3,
                  ];
                  return (
                    <div
                      key={contentObj.id}
                      className={
                        i % 2 === 0 || i === 0
                          ? styles.leftSideContent
                          : styles.rightSideContent
                      }
                    >
                      <div>
                        <img src={imageArr[i]} alt="logo" />
                      </div>
                      <div
                        className={
                          i % 2 === 0 || i === 0
                            ? styles.leftMainContent
                            : styles.rightMainContent
                        }
                      >
                        <h5
                          className={
                            i % 2 === 0 || i === 0
                              ? styles.leftSideHeader
                              : styles.rightSideHeader
                          }
                        >
                          {contentObj.name}
                        </h5>
                        <p className={styles.content}>{contentObj.content}</p>
                        {contentObj.showknowMore && (
                          <span
                            className={styles.knowMoreContent}
                            onClick={() => handleChange(contentObj.pId)}
                          >
                            KNOW MORE&gt;&gt;
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </foreignObject>
        </svg>
      ) : (
        <ProgramsMobile />
      )}
    </div>
  );
};
export default Programs;
