const Common = {
  back: "Back",
  career: "Career",
  lookDeeper: "Look Deeper",
  inView: "In-view",
  inviewHeading: "IN-VIEW",
  home: "Home",
  about: "About Us",
  ioac: "IOAC",
  product: "Our Products",
  services: "Our Services",
  socialConnect: "Social Connect",
  howWeWork: "How We Work",
  blog: "Blogs",
};
export default Common;
