export const programContent = [
  {
    id: 1,
    y: "5%",
    pId: 137,
    name: "Pre Nursery",
    position: "left",
    content: `A 2-year-old child is a bundle of energy and curiosity,
      constantly exploring and discovering their world with boundless
      enthusiasm. They are in a stage of rapId development, learning
      new skills and abilities at a remarkable pace. At this age, they
      are beginning to develop a sense of self and their place in the
      world, and require loving support and understanding about their
      emotions and interactions. It is a critical time for their
      growth and development, setting the foundation for a lifetime of
      learning and exploration.`,
    showknowMore: true,
  },
  {
    id: 2,
    y: "18.7%",
    pId: 138,
    name: "Nursery",
    position: "right",
    content: ` A 3-year-old child is a little explorer, ready to take on the
      world with their growing confidence and independence. They are
      creating their own identity, and are eager to assert their
      preferences and opinions. At this age, they are developing
      language skills and an increasing vocabulary, allowing them to
      express themselves more clearly and engage in more complex
      conversations.`,
    showknowMore: true,
  },
  {
    id: 3,
    y: "30.5%",
    pId: 139,
    name: "Kindergarten 1",
    position: "left",
    content: `A 4-year-old child is a little adventurer, ready to explore the
      world with their growing curiosity and creativity. They are
      developing a more sophisticated understanding of the world
      around them, and are eager to learn more about the people,
      places, and things they encounter. At this age, they are
      developing more complex language skills and a deeper
      understanding of grammar and syntax, allowing them to express
      themselves more effectively and engage in more complex
      conversations.`,
    showknowMore: true,
  },
  {
    id: 4,
    y: "44.3%",
    pId: 140,
    name: "Kindergarten 2",
    position: "right",
    content: `  A 5-year-old child is a little scholar, eager to learn and
      explore the world with their growing intellect and creativity.
      They are developing a deeper understanding of the world around
      them, and are eager to learn more about the people, places, and
      things they encounter. At this age, they are developing more
      advanced language skills, including an expanding vocabulary and
      a more sophisticated understanding of grammar and syntax,
      allowing them to express themselves with increasing precision
      and clarity.`,
    showknowMore: true,
  },
  {
    id: 5,
    y: "58.2%",
    pId: 141,
    name: "Grade 1",
    position: "left",
    content: ` A 6-year-old child is a budding learner, ready to dive into more
     structured and formal education with their growing intellectual and social skills. 
     They are developing a stronger sense of logic and reasoning, and are eager to tackle 
     new challenges and solve problems. At this age, they are expanding their reading 
     and writing abilities, and are beginning to understand more complex mathematical concepts.
      Their curiosity drives them to ask questions and seek deeper knowledge about 
      the world around them.`,
    showknowMore: false,
  },
  {
    id: 6,
    y: "70.8%",
    pId: 142,
    name: "Grade 2",
    position: "right",
    content: `A 7-year-old child is an inquisitive thinker, eager to deepen their understanding
     of the subjects they are passionate about. They are developing a more refined ability to
      concentrate and focus, enabling them to engage in more detailed and sustained learning
       activities. At this age, they are improving their critical thinking and analytical skills,
        and are able to participate in more advanced discussions and collaborative projects.
         Their enthusiasm for learning is matched by their growing ability to express 
         themselves creatively and confidently.`,
    showknowMore: false,
  },
  {
    id: 7,
    y: "84%",
    pId: 142,
    name: "Grade 3",
    position: "left",
    content: `An 8-year-old child is a confident explorer, ready to expand their 
    horizons with their increasing independence and self-assurance. They are honing
     their academic skills, becoming more proficient in reading, writing, and mathematics.
      At this age, they are able to handle more complex tasks and projects, demonstrating 
      greater responsibility and organization. Their social skills are also maturing, 
      allowing them to build stronger relationships and work effectively in team settings. 
      They are eager to apply their knowledge in practical and meaningful ways, making
       connections between what they learn in school and the world around them.`,
    showknowMore: false,
  },
];
