export const ioacSpecialContent = [
  {
    id: 1,
    content: `This scientific and research oriented unique approach acts as a
      pillar for a parent (facilitator or a guardian) to be rightly
      informed about parenting/facilitating and “learn the child”.`,
  },
  {
    id: 2,
    content: ` A pedagogy that takes the children from the known to the unknown
      by tapping into the core philosophies of Reggio, Montessori, IB,
      Process & Development based drama, Gurukul, Multiple
      Intelligence and new age practices across the world to joyfully
      co-live with nature, humans, animals, technology and AI to
      unfold cognition and PSE skills (personal, social, emotional)`,
  },
  {
    id: 3,
    content: ` Curated by experts in the field of early childhood development
      to discover your child’s philosophical thinking, aesthetic
      intelligence, design thinking abilities, creative expressions
      amongst other key skills required for the 21st century
      with the help of artificial intelligence.`,
  },
  {
    id: 4,
    content: `Experience the Image of a Child platform with over 20,000 activities, featuring auto-evaluation and instant progress tracking to ensure every child excels effortlessly.`,
  },
  {
    id: 5,
    content: ` One of a kind Virtual Atelier where you and your child/children
      can share a safe and enriching growing experience.`,
  },
  {
    id: 6,
    content: ` The methodology perches on an intricate auto evaluating, highly
      scalable and an interactive platform built with one million
      codes.`,
  },
];
export const educationModelContent = [
  {
    id: 1,
    title: `Holistic Pedagogy`,
    content: [
      `Integration of Reggio Emilia, Montessori, International Baccalaureate (IB), Process & Development-based drama, Gurukul, and Multiple Intelligence approaches.`,
      `Emphasis on joyful co-living with nature, humans, animals, technology, and AI.`,
      `Focus on developing cognitive, personal, social, and emotional (PSE) skills.`,
    ],
  },
  {
    id: 2,
    title: `Personalized Learning`,
    content: [
      `AI-driven personalized learning experiences tailored to each child’s unique needs and interests.`,
      `Intelligent co-tutoring systems that support both adults and children.`,
    ],
  },
  {
    id: 3,
    title: `Expert Curation`,
    content: [
      `Programs curated by experts in early childhood development.`,
      `Emphasis on discovering and enhancing philosophical thinking, aesthetic intelligence, design thinking abilities, and creative expressions.`,
    ],
  },
  {
    id: 4,
    title: `Philosophically Provocative and Aesthetically Enriching`,
    content: [
      `Mindfully curated learning realms offering philosophically provocative and engaging explorations.`,
      `Encouragement of deep thinking and creativity.`,
    ],
  },
  {
    id: 5,
    title: `Lifelong Learning Foundation`,
    content: [
      `Comprehensive educational approach that prepares children for the 21st century.`,
      `Support for lifelong learning and adaptability in an ever-evolving world.`,
    ],
  },
];

export const platFormContent = [
  {
    id: 1,
    title: `Virtual Atelier`,
    content: [
      `A unique, safe, and enriching online environment for children and parents to share learning experiences.`,
      `Interactive and immersive virtual sessions that foster creativity and collaboration.`,
    ],
  },
  {
    id: 2,
    title: `Detailed Digital Childhood Profiles`,
    content: [
      `Creation of a comprehensive digital record of each child’s learning journey.`,
      `Documentation of milestones, achievements, and unique traits.`,
    ],
  },
  {
    id: 3,
    title: `Advanced Analytics and Diagnostics`,
    content: [
      `Use of AI and predictive analytics to provide personalized learning pathways and support strategies.`,
      `Diagnostic tools to identify learning difficulties and guide effective interventions.`,
    ],
  },
  {
    id: 4,
    title: `Highly Scalable and Interactive`,
    content: [
      `An intricate platform built with one million lines of code, ensuring scalability and interactivity.`,
      `Adaptable to the diverse needs of schools and educational institutions.`,
    ],
  },
  {
    id: 5,
    title: `Seamless Integration`,
    content: [
      `Designed to integrate seamlessly with existing school curricula.`,
      `Provides training and resources for educators to implement innovative approaches effectively.`,
    ],
  },
  {
    id: 6,
    title: `Enhanced Learning Strategies`,
    content: [
      `Provides educators with deep insights into each child’s learning journey.`,
      `Supports the development of effective teaching strategies and personalized educational support.`,
    ],
  },
];

export const integrateSchoolContent = `Integrating Image of a Child (IOAC) into your school's curriculum brings unparalleled value by combining traditional educational philosophies with cutting-edge AI technology. IOAC’s innovative approach offers personalized learning experiences tailored to each student's unique needs, fostering cognitive, social, and emotional development. Our detailed digital profiles and portfolios provide educators with deep insights into each child's learning journey, enabling more effective teaching strategies and support. By adopting IOAC, schools can enhance their educational offerings, stay at the forefront of educational innovation.`;
