import React, { Suspense, lazy } from "react";
import HiringLayout from "./screens/hiring/HiringLayout";
import { Route, Switch } from "react-router-dom";
import Header from "globals/components/header/Header";
import "assets/scss/main.scss";
import Footer from "globals/components/Footer/Footer";
import HomeScreen from "screens/home/HomeScreen";
import DefaultLoader from "globals/components/DefaultLoader";
import PrivacyPolicy from "globals/components/Footer/PrivacyPolicy";

const ProductScreen = lazy(() => import("screens/product/ProductScreen"));
const AboutScreen = lazy(() => import("screens/about/AboutScreen"));
const SocialConnect = lazy(() => import("screens/socialConnect/SocialConnect"));
const BlogScreen = lazy(() => import("screens/blog/BlogScreen"));
const OurServices = lazy(() => import("screens/ourservices/OurServices"));
const IoacScreen = lazy(() => import("screens/ioac/IoacScreen"));
const HowWeWork = lazy(() => import("screens/howWeWork/HowWeWork"));
import contestScreen from "screens/contest/ContestScreen";
const programsOffered = lazy(() =>
  import("screens/programsInternal/ProgramsOffered")
);
const HomeSchooling = lazy(() => import("screens/homeSchool/HomeSchooling"));
const BlogDetailed = lazy(() => import("screens/homeSchool/BlogDetailed"));
const Franchise = lazy(() => import("screens/product/Franchise"));
const BootCampScreen = lazy(() => import("screens/bootcamp/BootCampScreen"));

class App extends React.Component {
  render = () => {
    return (
      <>
        <Header />
        <Suspense fallback={<DefaultLoader />}>
          <Switch>
            <Route exact path="/hiring" component={HiringLayout} />
            <Route exact path="/home" component={HomeScreen} />
            <Route exact path="/about" component={AboutScreen} />
            <Route exact path="/ioac" component={IoacScreen} />
            <Route exact path="/product" component={ProductScreen} />
            <Route exact path="/services" component={OurServices} />
            <Route exact path="/socialConnect" component={SocialConnect} />
            <Route exact path="/howWeWork" component={HowWeWork} />
            <Route exact path="/programsOffered" component={programsOffered} />
            <Route exact path="/blogDetailed" component={BlogDetailed} />
            <Route exact path="/parents" component={HomeSchooling} />
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
            <Route exact path="/termsAndConditions" component={PrivacyPolicy} />
            <Route exact path="/deleteRequest" component={PrivacyPolicy} />
            <Route exact path="/contest" component={contestScreen} />
            <Route exact path="/franchise" component={Franchise} />
            <Route exact path="/bootcamp" component={BootCampScreen} />
            <Route exact path="/">
              <HomeScreen />
            </Route>
            <Route exact path="/blog" component={BlogScreen} />
          </Switch>
          <Footer />
        </Suspense>
      </>
    );
  };
}

export default App;
