import React from "react";
import styles from "./scss/ProgrameMobile.module.scss";
import programs from "assets/images/home/programesMobile.webp";
import prenurseryImage from "assets/images/home/prenursery.webp";
import nursery from "assets/images/home/nursery.webp";
import kindergarten1 from "assets/images/home/kindergarten1.webp";
import kindergarten2 from "assets/images/home/kindergarten2.webp";
import grade1 from "assets/images/home/grade1.webp";
import grade2 from "assets/images/home/grade2.webp";
import grade3 from "assets/images/home/grade3.webp";
import programeBg from "assets/images/home/mobilePrograme.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { programContent } from "./ProgramsData";
import { useHistory } from "react-router-dom";
const ProgramsMobile = () => {
  const history = useHistory();
  const programImages = [
    prenurseryImage,
    nursery,
    kindergarten1,
    kindergarten2,
    grade1,
    kindergarten2,
    grade2,
    grade3,
  ];

  const handleChange = (data) => {
    history.push("/programsOffered", { state: data });
  };

  return (
    <div className={styles.background}>
      <svg
        width="450"
        height="3210"
        viewBox="0 0 465 3210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <image href={programeBg} className={styles.programeBg} />
        <foreignObject x="6%" y="0" width="65%" height="26%">
          <LazyLoadImage src={programs} width="100%" />
        </foreignObject>
        {programContent?.map((e, i) => {
          return (
            <>
              <foreignObject x="5%" y={e.y} width="85%" height="16%">
                <div className={styles.mobileContent}>
                  <div>
                    <LazyLoadImage src={programImages[i]} alt="pg" />
                  </div>
                  <div className={styles.leftMainContent}>
                    <h5 className={styles.leftSideHeader}>{e.name}</h5>
                    <p>{e.content}</p>
                  </div>
                  {e.showknowMore && (
                    <span
                      className={styles.knowMoreContent}
                      onClick={() => handleChange(e.pId)}
                    >
                      KNOW MORE&gt;&gt;
                    </span>
                  )}
                </div>
              </foreignObject>
            </>
          );
        })}
      </svg>
    </div>
  );
};
export default ProgramsMobile;
