/* eslint-disable indent */
import { React } from "react";
import { withTranslation } from "react-i18next";
import style from "./scss/productScreen.module.scss";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Icon,
  Grid,
  GridColumn,
  Form,
  FormGroup,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import regChild from "../../assets/images/RegChild.webp";
import closeIcon from "../../assets/images/closeIcon.webp";
import { resturls } from "globals/utils/apiurls";
import GlobalService from "services/GlobalService";

const RegistrationForm = ({ handleRegModal, type, content, title }) => {
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  const homeSchooling = type === "homeSchooling";

  const formSubmit = (formData) => {
    const formContent = new FormData();
    const eventName = homeSchooling
      ? "IOAC-EDTech Franchise Enquiry"
      : "Image of a child Demo";
    formContent.append("email", formData.email);
    formContent.append("mobilenumber", formData.phone);
    formContent.append("location", formData.location);
    formContent.append("userType", formData.message);
    formContent.append("registrationPurpose", eventName);
    formContent.append("type", "IOAC");
    if (homeSchooling) {
      formContent.append("name", formData.kidName);
      formContent.append("parentName", formData.name);
      formContent.append("age", formData.age);
      formContent.append("language", formData.language.join(", "));
      formContent.append("templateName", "EDTech Franchise Enquiry");
    } else {
      formContent.append("name", formData.name);
      formContent.append("institution", formData.organization);
    }
    GlobalService.generalSelect(
      (respdata) => {
        if (respdata) {
          //
        }
      },
      `${resturls.registerWebsiteUser}`,
      formContent,
      "POST",
      "pro"
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    location: Yup.string().required("Location is required"),
    message: Yup.string(),
    ...(homeSchooling
      ? {
          kidName: Yup.string().required("Kid name is required"),
          age: Yup.string().required("Age is required"),
          language: Yup.array()
            .min(1, "Preferred language is required")
            .required("Preferred language is required"),
        }
      : { organization: Yup.string().required("Organization is required") }),
  });

  const ageOptions = [
    { key: "2-3", value: "2-3", text: "2-3 years" },
    { key: "3-4", value: "3-4", text: "3-4 years" },
    { key: "4-5", value: "4-5", text: "4-5 years" },
    { key: "5-6", value: "5-6", text: "5-6 years" },
  ];

  const languageOptions = [
    { key: "english", value: "english", text: "English" },
    { key: "hindi", value: "hindi", text: "Hindi" },
    { key: "tamil", value: "tamil", text: "Tamil" },
    { key: "kannada", value: "kannada", text: "Kannada" },
    { key: "telugu", value: "telugu", text: "Telugu" },
    { key: "malayalam", value: "malayalam", text: "Malayalam" },
  ];

  const finalHeading = content || title || "Become an IOAC EdTech Franchisee";
  return (
    <>
      <div className={style.formWrapper}>
        <div className={style.formHeaderWrapper}>
          <h2 className={style.heading}>{finalHeading}</h2>
          <div
            className={style.closeIcon}
            onClick={() => handleRegModal(false)}
          >
            <img src={closeIcon} alt="logo" />
          </div>
        </div>
        <Grid>
          <GridColumn width={isResponsive ? 16 : 10}>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                organization: "",
                location: "",
                message: "",
                kidName: "",
                age: "",
                language: [],
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                formSubmit(values);
                resetForm();
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                touched,
                errors,
                values,
                handleBlur,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Input
                      fluid
                      name="name"
                      label="Name"
                      type="text"
                      value={values.name}
                      className={style.formField}
                      onChange={handleChange}
                      error={touched.name && errors.name}
                      onBlur={handleBlur}
                    />
                    <Form.Input
                      fluid
                      name="email"
                      type="text"
                      label="Email*"
                      value={values.email}
                      className={style.formField}
                      onChange={handleChange}
                      error={touched.email && errors.email}
                      onBlur={handleBlur}
                    />
                    <Form.Input
                      fluid
                      name="phone"
                      type="number"
                      label="Phone*"
                      value={values.phone}
                      className={style.formField}
                      onChange={handleChange}
                      error={touched.phone && errors.phone}
                      onBlur={handleBlur}
                    />
                    <FormGroup widths="equal">
                      {homeSchooling ? (
                        <>
                          <Form.Input
                            fluid
                            name="kidName"
                            type="text"
                            label="Kid Name"
                            value={values.kidName}
                            className={style.formField}
                            onChange={handleChange}
                            error={touched.kidName && errors.kidName}
                            onBlur={handleBlur}
                          />
                          <Form.Field
                            fluid
                            name="age"
                            label="Age"
                            control={Dropdown}
                            selection
                            options={ageOptions}
                            value={values.age}
                            className={style.formField}
                            onChange={(e, { value }) =>
                              setFieldValue("age", value)
                            }
                            error={touched.age && errors.age}
                            onBlur={handleBlur}
                          />
                        </>
                      ) : (
                        <Form.Input
                          fluid
                          name="organization"
                          type="text"
                          label="Organisation name"
                          value={values.organization}
                          className={style.formField}
                          onChange={handleChange}
                          error={touched.organization && errors.organization}
                          onBlur={handleBlur}
                        />
                      )}
                      <Form.Input
                        fluid
                        name="location"
                        type="text"
                        label="Location"
                        value={values.location}
                        className={style.formField}
                        onChange={handleChange}
                        error={touched.location && errors.location}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                    <Form.Field
                      fluid
                      name="language"
                      label="Preferred Language"
                      control={Dropdown}
                      selection
                      multiple
                      options={languageOptions}
                      value={values.language}
                      className={style.formField}
                      onChange={(e, { value }) =>
                        setFieldValue("language", value)
                      }
                      error={touched.language && errors.language}
                      onBlur={handleBlur}
                    />
                    <Form.Field
                      fluid
                      name="message"
                      label="Message*"
                      value={values.message}
                      className={style.formField}
                      onChange={handleChange}
                      control={TextArea}
                    />
                    <Button
                      size="big"
                      type="submit"
                      className={style.submitBtn}
                      color="orange"
                    >
                      Submit <Icon name="arrow right"></Icon>
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </GridColumn>
          {!isResponsive && (
            <GridColumn width={6} className={style.imageGrid}>
              <img src={regChild} alt="logo" />
            </GridColumn>
          )}
        </Grid>
      </div>
    </>
  );
};
export default withTranslation("product")(RegistrationForm);
