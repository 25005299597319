import React, { useEffect, useRef, useState } from "react";
import styles from "./scss/contestScreen.module.scss";
import parent1 from "assets/images/ioac/parent1.webp";
import parent2 from "assets/images/ioac/parent2.webp";
import profilePic from "assets/images/ioac/profilePic.webp";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import parse from "html-react-parser";
import { Icon, Image } from "semantic-ui-react";
import closeIcon from "assets/images/ioac/closeIcon.webp";
import tickIcon from "assets/images/ioac/tickIcon.webp";
import parentMob from "assets/images/ioac/parentMob.webp";
import { resturls } from "globals/utils/apiurls";
import GlobalService from "services/GlobalService";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const ContestScreen = () => {
  const fileInputRef = useRef(null);
  const [initialValues, setInitialValues] = useState({
    parentName: "",
    childName: "",
    location: "",
    mobile: "",
    email: "",
    childAge: "",
    instaId: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    fileInput: { file: [], path: "" },
  });
  const [contestData, setContestData] = useState();
  const [timer, setTimer] = useState(null);
  const [isOtp, setIsOtp] = useState({
    open: false,
    status: "progress",
    name: "send-otp",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus === true && emessage === "success") {
          setContestData(data);
        }
      },
      `${resturls.obtainContestContent}`,
      {},
      "POST",
      "pro"
    );
  }, []);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && isOtp.open) {
      clearInterval(interval);
      setIsOtp({
        open: true,
        status: "progress",
        name: "resend-otp",
      });
    }
    return () => clearInterval(interval);
  }, [timer, isOtp.open]);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleSaveSubmission = () => {
    if (
      initialValues.parentName.length > 0 &&
      initialValues.childName.length > 0 &&
      initialValues.childAge.length > 0 &&
      initialValues.location.length > 0 &&
      initialValues.mobile.length > 0 &&
      initialValues.instaId.length > 0
    ) {
      const formContent = new FormData();
      formContent.append("parentName", initialValues.parentName);
      formContent.append("name", initialValues.childName);
      formContent.append("location", initialValues.location);
      formContent.append("mobilenumber", initialValues.mobile);
      formContent.append("email", initialValues.email);
      formContent.append("age", initialValues.childAge);
      formContent.append("utmSource", initialValues.instaId);
      formContent.append("templateName", "contestTemplate");
      initialValues.fileInput?.file.forEach((element) => {
        formContent.append("uploadfile", element);
      });
      formContent.append("type", "IOAC");
      formContent.append("registrationPurpose", contestData?.purpose);
      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage } = respdata;
          if (estatus === true && emessage === "success") {
            setInitialValues({
              parentName: "",
              childName: "",
              location: "",
              mobile: "",
              email: "",
              childAge: "",
              instaId: "",
              otp1: "",
              otp2: "",
              otp3: "",
              otp4: "",
              otp5: "",
              otp6: "",
              fileInput: { file: [], path: "" },
            });
            setIsOtp({
              open: false,
              status: "progress",
              name: "send-otp",
            });
            setIsSubmitted(true);
          }
        },
        `${resturls.registerWebsiteUser}`,
        formContent,
        "POST",
        "pro"
      );
    } else {
      toast({
        type: "warning",
        description: "please fill all the details",
        time: 3000,
        size: "small",
      });
    }
  };

  const sendVerificationOTP = () => {
    setInitialValues((prev) => ({
      ...prev,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    }));
    const obj = {
      email: initialValues.email,
      name: initialValues.childName,
      type: "IOAC",
    };
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus === true && emessage === "success") {
          setIsOtp({
            open: true,
            status: "progress",
            name: "verify-otp",
          });
          setTimer(data.remainingTime);
        }
      },
      `${resturls.sendVerificationOTP}`,
      obj,
      "POST",
      "pro"
    );
  };

  const verifyOTP = () => {
    const obj = {
      email: initialValues.email,
      otp: `${initialValues.otp1}${initialValues.otp2}${initialValues.otp3}${initialValues.otp4}${initialValues.otp5}${initialValues.otp6}`,
    };
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage } = respdata;
        if (estatus === true && emessage === "success") {
          setIsOtp({
            ...isOtp,
            status: "success",
            open: false,
          });
        } else {
          setIsOtp({
            ...isOtp,
            status: "failure",
            open: true,
          });
          toast({
            type: "warning",
            description: "Otp didnt match",
            time: 3000,
            size: "small",
          });
        }
      },
      `${resturls.verifyOTP}`,
      obj,
      "POST",
      "pro"
    );
  };

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === "fileInput") {
      const files = Array.from(e.target.files).map((file) => file);
      setInitialValues({
        ...initialValues,
        [name]: {
          file: files,
          path: e.target?.files[0].name,
        },
      });
    } else if (name.startsWith("otp")) {
      setInitialValues((prev) => ({
        ...prev,
        [name]: e.target.value.slice(0, 1),
      }));
    } else if (!name.startsWith("otp")) {
      setInitialValues((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
    if (isSubmitted) {
      setIsSubmitted(false);
    }
  };

  const resetEmail = () => {
    setIsOtp({
      open: false,
      status: "progress",
      name: "send-otp",
    });
    setInitialValues({
      ...initialValues,
      email: "",
    });
  };

  const handleInput = (e, index) => {
    const currentInput = e.target;
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const inputs = Array.from(
      currentInput.parentElement.querySelectorAll("input")
    );
    const prevInput = inputs[prevIndex];
    const nextInput = inputs[nextIndex];

    if (
      currentInput.value.length >= currentInput.maxLength &&
      nextInput &&
      e.keyCode !== 8
    ) {
      nextInput.focus();
    } else if (
      currentInput.value.length >= currentInput.maxLength &&
      e.keyCode === 8 &&
      index > 0
    ) {
      e.preventDefault();
      currentInput.value = "";
      currentInput.focus();
      setInitialValues((prev) => ({ ...prev, [currentInput.name]: "" }));
    } else if (
      currentInput.value.length === 0 &&
      e.keyCode === 8 &&
      index > 0
    ) {
      prevInput.focus();
    }

    if (e.keyCode === 39 && nextInput) {
      e.preventDefault();
      nextInput.focus();
    }

    if (e.keyCode === 37 && prevInput) {
      e.preventDefault();
      prevInput.focus();
    }
    return null;
  };

  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const otpDisable =
    isOtp.name === "send-otp" ? !emailRegex.test(initialValues.email) : false;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.contestDiv}>
        <Image
          className={styles.parentImg}
          size="medium"
          src={isResponsive ? parentMob : parent2}
          alt=""
        />
        <div className={styles.contestCont}>
          {contestData?.content && parse(contestData?.content)}
        </div>
        <Image
          className={styles.parentImg1}
          size="medium"
          src={parent1}
          alt=""
        />
      </div>
      {isResponsive ? (
        <>
          <SemanticToastContainer
            position="top-right"
            className={styles.semanticToast}
          />
          <div className={styles.formDiv}>
            <div className={styles.secondaryDiv}>
              <div className={styles.profileDiv}>
                <Image className={styles.profilePic} src={profilePic} />
              </div>
              <div className={styles.rightDiv}>
                <p className={styles.formIns}>
                  Fill the details and upload your submission below
                </p>
                <div className={styles.formDetails}>
                  <div className={styles.rowInput}>
                    <input
                      type="text"
                      name="parentName"
                      className={styles.formInput}
                      placeholder="Parent name"
                      onChange={(e) => handleChange(e)}
                      value={initialValues.parentName}
                    />
                    <input
                      type="text"
                      name="childName"
                      className={styles.formInput}
                      placeholder="Child name"
                      onChange={(e) => handleChange(e)}
                      value={initialValues.childName}
                    />
                  </div>
                  <div className={styles.rowInput}>
                    <input
                      type="text"
                      name="location"
                      className={styles.formInput}
                      placeholder="Location"
                      onChange={(e) => handleChange(e)}
                      value={initialValues.location}
                    />
                    <input
                      type="number"
                      name="mobile"
                      className={styles.formInput}
                      placeholder="Mobile"
                      onChange={(e) => handleChange(e)}
                      value={initialValues.mobile}
                    />
                  </div>
                  <div
                    className={`${
                      isOtp.open && isResponsive
                        ? styles.rowInput3
                        : styles.rowInput2
                    }`}
                  >
                    {!isOtp.open && isOtp.status !== "success" ? (
                      <>
                        <input
                          type="text"
                          className={styles.formInput}
                          name="email"
                          placeholder="E-mail"
                          onChange={(e) => handleChange(e)}
                          value={initialValues.email}
                        />
                      </>
                    ) : !isOtp.open && isOtp.status === "success" ? (
                      <div className={styles.emailDiv}>
                        <div className={styles.iconWrapper}>
                          <div className={styles.iconDiv}>
                            <p className={styles.confirmedEmail}>
                              {initialValues.email}
                            </p>
                            <Image
                              className={styles.closeIcon}
                              onClick={() => resetEmail()}
                              src={closeIcon}
                            />
                          </div>
                          <Image src={tickIcon} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {[...Array(6)].map((_, i) => (
                          <input
                            type="number"
                            className={styles.otpInput}
                            name={`otp${i + 1}`}
                            onChange={(e) => handleChange(e)}
                            value={initialValues[`otp${i + 1}`]}
                            key={`designDot_${i + 1}`}
                            onInput={(e) => handleInput(e, i)}
                            onKeyDown={(e) => handleInput(e, i)}
                            maxLength={1}
                          />
                        ))}
                      </>
                    )}
                    {isOtp.status !== "success" && (
                      <button
                        onClick={
                          isOtp.open && isOtp.name !== "resend-otp"
                            ? () => verifyOTP()
                            : () => sendVerificationOTP()
                        }
                        className={`${styles.otpBtn} ${
                          otpDisable ? styles.otpBtnDisable : ""
                        }`}
                        disabled={otpDisable}
                      >
                        {isOtp.name}
                      </button>
                    )}
                    {isOtp.open &&
                      timer !== 0 &&
                      (isResponsive ? (
                        <p className={styles.timerCount}>{timer} seconds</p>
                      ) : (
                        <p className={styles.timerCount}>
                          Time remaining: {timer} seconds
                        </p>
                      ))}
                  </div>
                  <div className={styles.rowInput}>
                    <input
                      type="text"
                      className={styles.formInput}
                      name="childAge"
                      placeholder="Child's Age (2-8)"
                      onChange={(e) => handleChange(e)}
                      value={initialValues.childAge}
                    />
                    <input
                      type="text"
                      className={styles.formInput}
                      name="instaId"
                      placeholder="Instagram id"
                      onChange={(e) => handleChange(e)}
                      value={initialValues.instaId}
                    />
                  </div>
                  <div
                    className={`${styles.colInput} ${
                      isSubmitted && styles.submitted
                    }`}
                  >
                    <div className={styles.fileContainer}>
                      <div className={styles.uploadBtnWrapper}>
                        <button
                          type="file"
                          className={`${styles.uploadBtn} ${
                            initialValues.fileInput.path !== ""
                              ? styles.fileUploaded
                              : ""
                          }`}
                          onClick={handleFileUploadClick}
                        >
                          upload submission
                        </button>
                      </div>
                      {initialValues.fileInput.file.length > 0 && (
                        <div className={styles.fileWrapper}>
                          {initialValues.fileInput.file.map((ele, ind) => (
                            <p key={ind + 1}>{ele.name}</p>
                          ))}
                        </div>
                      )}
                    </div>
                    {isSubmitted && (
                      <div className={styles.successMsgContainer}>
                        <span className={styles.mobSuccessMsg}>
                          Thanks for your participation, Details submitted
                          successfully
                        </span>
                      </div>
                    )}
                    <button
                      type="submit"
                      onClick={() => handleSaveSubmission()}
                      className={`${styles.submitBtn} ${
                        initialValues.fileInput.path !== "" &&
                        isOtp.status === "success"
                          ? styles.successBtn
                          : styles.disabledBtn
                      }`}
                      disabled={
                        initialValues.fileInput.path === "" ||
                        isOtp.status !== "success"
                      }
                    >
                      submit <Icon name="arrow right"></Icon>
                    </button>
                    <input
                      multiple
                      type="file"
                      className={styles.fileInput}
                      ref={fileInputRef}
                      name="fileInput"
                      accept="image/*, video/*"
                      style={{ display: "none" }}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1920 715"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1314.16 509.283C1330.81 584.915 1395.88 564.474 1435.21 541.989C1473.04 519.503 1524.49 425.454 1572.91 462.268C1612.24 492.93 1562.32 564.474 1559.29 605.377C1556.27 640.127 1571.4 666.7 1600.14 666.7C1636.45 664.656 1644.02 619.685 1660.66 586.98C1669.74 566.539 1684.87 544.053 1704.53 550.186C1728.74 558.362 1724.31 574.674 1736.41 591.027C1762.13 629.865 1808.94 599.244 1831.64 578.803C1858.87 552.23 1901.23 546.015 1926.97 580.765C1955.71 617.559 1946.18 667.092 1926.68 713.715"
            stroke="#F37335"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <path
            d="M-166.477 99.5035C-149.834 175.136 -84.7623 154.695 -45.4257 132.209C-7.60214 109.724 43.8532 15.6743 92.2675 52.489C131.604 83.1506 81.6768 154.695 78.651 195.597C75.6251 230.347 90.7545 256.921 119.5 256.921C155.811 254.877 163.376 209.906 180.018 177.2C189.096 156.759 204.225 134.274 223.894 140.406C248.101 148.583 243.669 164.894 255.772 181.247C281.492 220.085 328.302 189.465 350.996 169.024C378.229 142.45 420.592 136.236 446.327 170.985C475.073 207.779 465.537 257.313 446.037 303.935"
            stroke="#F37335"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <path
            d="M769.606 610.806C937.728 602.453 1405.58 613.174 1615.73 611.608C1672.45 633.534 1654.99 53.1645 1647.23 31.2391C1639.47 9.31372 1474.09 18.7103 1404.84 18.7103C1335.58 18.7103 299.749 -18.7663 282.436 16.2099C265.122 51.1861 259.749 351.355 267.51 442.189C275.271 533.023 269.898 623.335 299.749 610.806C329.6 598.277 559.454 621.247 769.606 610.806Z"
            fill="#A4D1CD"
            stroke="#1E1E1E"
            strokeWidth="2"
          />
          <path
            d="M774.191 590.565C938.264 582.77 1394.85 592.776 1599.94 591.314C1655.29 611.777 1638.26 70.1268 1630.68 49.6641C1623.11 29.2015 1461.71 37.9711 1394.13 37.9712C1326.54 37.9712 315.647 2.99476 298.751 35.6375C281.854 68.2803 276.61 348.424 284.184 433.198C291.759 517.971 286.515 602.258 315.647 590.565C344.78 578.873 569.099 600.31 774.191 590.565Z"
            fill="white"
            stroke="#1E1E1E"
            strokeWidth="2"
          />
          <foreignObject x="230" y="0" width="1400" height="600">
            <div className={styles.formDiv}>
              <div className={styles.secondaryDiv}>
                <div className={styles.profileDiv}>
                  <Image className={styles.profilePic} src={profilePic} />
                </div>
                <div className={styles.rightDiv}>
                  <p className={styles.formIns}>
                    Fill the details and upload your submission below
                  </p>
                  <div className={styles.formDetails}>
                    <div className={styles.rowInput}>
                      <input
                        type="text"
                        name="parentName"
                        className={styles.formInput}
                        placeholder="Parent name"
                        onChange={(e) => handleChange(e)}
                        value={initialValues.parentName}
                      />
                      <input
                        type="text"
                        name="childName"
                        className={styles.formInput}
                        placeholder="Child name"
                        onChange={(e) => handleChange(e)}
                        value={initialValues.childName}
                      />
                    </div>
                    <div className={styles.rowInput}>
                      <input
                        type="text"
                        name="location"
                        className={styles.formInput}
                        placeholder="Location"
                        onChange={(e) => handleChange(e)}
                        value={initialValues.location}
                      />
                      <input
                        type="number"
                        name="mobile"
                        className={styles.formInput}
                        placeholder="Mobile"
                        onChange={(e) => handleChange(e)}
                        value={initialValues.mobile}
                      />
                    </div>
                    <div className={styles.rowInput2}>
                      {!isOtp.open && isOtp.status !== "success" ? (
                        <>
                          <input
                            type="text"
                            className={styles.formInput}
                            name="email"
                            placeholder="E-mail"
                            onChange={(e) => handleChange(e)}
                            value={initialValues.email}
                          />
                        </>
                      ) : isOtp.open === false && isOtp.status === "success" ? (
                        <div className={styles.emailDiv}>
                          <div className={styles.iconWrapper}>
                            <div className={styles.iconDiv}>
                              <p className={styles.confirmedEmail}>
                                {initialValues.email}
                              </p>
                              <Image
                                className={styles.closeIcon}
                                onClick={() => resetEmail()}
                                src={closeIcon}
                              />
                            </div>
                            <Image src={tickIcon} />
                          </div>
                        </div>
                      ) : (
                        <>
                          {[...Array(6)].map((_, i) => (
                            <input
                              type="number"
                              className={styles.otpInput}
                              name={`otp${i + 1}`}
                              onChange={(e) => handleChange(e)}
                              value={initialValues[`otp${i + 1}`]}
                              key={`designDot_${i + 1}`}
                              onInput={(e) => handleInput(e, i)}
                              onKeyDown={(e) => handleInput(e, i)}
                              maxLength={1}
                            />
                          ))}
                        </>
                      )}
                      {isOtp.status !== "success" && (
                        <button
                          onClick={
                            isOtp.open && isOtp.name !== "resend-otp"
                              ? () => verifyOTP()
                              : () => sendVerificationOTP()
                          }
                          className={`${styles.otpBtn} ${
                            otpDisable ? styles.otpBtnDisable : ""
                          }`}
                          disabled={otpDisable}
                        >
                          {isOtp.name}
                        </button>
                      )}
                      {isOtp.open && timer !== 0 && (
                        <p className={styles.timerCount}>
                          Time remaining: {timer} seconds
                        </p>
                      )}
                    </div>
                    <div className={styles.rowInput}>
                      <input
                        type="text"
                        className={styles.formInput}
                        name="childAge"
                        placeholder="Child's Age (2-8)"
                        onChange={(e) => handleChange(e)}
                        value={initialValues.childAge}
                      />
                      <input
                        type="text"
                        className={styles.formInput}
                        name="instaId"
                        placeholder="Instagram id"
                        onChange={(e) => handleChange(e)}
                        value={initialValues.instaId}
                      />
                    </div>
                    <div
                      className={`${styles.colInput} ${
                        isSubmitted && styles.submitted
                      }`}
                    >
                      <div className={styles.fileContainer}>
                        <div className={styles.uploadBtnWrapper}>
                          <button
                            type="file"
                            className={`${styles.uploadBtn} ${
                              initialValues.fileInput.path !== ""
                                ? styles.fileUploaded
                                : ""
                            }`}
                            onClick={handleFileUploadClick}
                          >
                            upload submission
                          </button>
                        </div>
                        {initialValues.fileInput.file.length > 0 && (
                          <div className={styles.fileWrapper}>
                            {initialValues.fileInput.file.map((ele, ind) => (
                              <p key={ind + 1}>{ele.name}</p>
                            ))}
                          </div>
                        )}
                      </div>
                      {isSubmitted && (
                        <div className={styles.successMsgContainer}>
                          <span className={styles.successMsg}>
                            Thanks for your participation, Details submitted
                            successfully
                          </span>
                        </div>
                      )}
                      <button
                        type="submit"
                        onClick={() => handleSaveSubmission()}
                        className={`${styles.submitBtn} ${
                          initialValues.fileInput.path !== "" &&
                          isOtp.status === "success"
                            ? styles.successBtn
                            : styles.disabledBtn
                        }`}
                        disabled={
                          initialValues.fileInput.path === "" ||
                          isOtp.status !== "success"
                        }
                      >
                        submit <Icon name="arrow right"></Icon>
                      </button>
                      <input
                        multiple
                        type="file"
                        className={styles.fileInput}
                        ref={fileInputRef}
                        name="fileInput"
                        accept="image/*, video/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </foreignObject>
          <foreignObject x="1250" y="50" width="350" height="100">
            <SemanticToastContainer
              position="bottom-right"
              className={styles.semanticToast}
            />
          </foreignObject>
        </svg>
      )}
    </div>
  );
};

export default ContestScreen;
