import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "globals/locale/en/EnAll";

const resources = { en };

i18n.use(LanguageDetector).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  ns: ["common"],
  defaultNS: "common",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
