import React from "react";
import style from "./defaultLoader.module.scss";

const DefaultLoader = () => {
  return (
    <>
      <div className={style.iocLogoWrapper}>
        <img className={style.iocLogo} src="/logo.webp" alt="IOAC" />
        <img
          className={style.iocLogoLoader}
          src="/logoCircle.webp"
          alt="IOAC"
        />
      </div>
    </>
  );
};

export default DefaultLoader;
