import React, { useEffect, useState } from "react";
import HearderImg from "assets/images/home/earlyYearsHeader.webp";
import HeaderMobileImg from "assets/images/home/earlyYearsMobileHeader.webp";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import styles from "./EarlyYears.module.scss";

const EarleyYears = ({ children, content }) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setwindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <div className={styles.headerImg}>
        {windowWidth > mediaBreakpoint.bigTablet ? (
          <img
            src={HearderImg}
            className={styles.earlyYearsImg}
            alt="earlyYears"
          />
        ) : (
          <picture>
            <img
              src={HeaderMobileImg}
              className={styles.earlyYearsImg}
              alt="earlyYearsImg"
            />
          </picture>
        )}
      </div>
      <div>
        <h2 className={styles.header}>EARLY YEARS</h2>
        {content === undefined ? (
          <>
            <div className={styles.primaryContent}>
              Because, Children are not meant for institutionalized
              environments! Their spaces range from the CLOUD to the CLOUD and
              beyond.
            </div>
            <div
              className={
                windowWidth > mediaBreakpoint.bigTablet
                  ? styles.clr20
                  : styles.clr10
              }
            />
            <div className={styles.primaryContent}>
              Introducing a pedagogy that is designed to incorporate aesthetics
              and futuristic learning approaches that leverage the power of
              Artificial Intelligence (AI) and advanced technologies for the
              forthcoming Earth 4.0. This includes the use of intelligent
              co-tutoring systems for an adult and a child, to provide
              personalized learning experiences. The mindfully curated pockets
              of learning-realms that provide philosophically provocative and
              engaging explorations, along with its predictive analytics based
              on the concept of “classification & clustering” to help to
              identify areas of integral connection to unwrap the eclectic
              individuality of your child.
            </div>
          </>
        ) : (
          <div className={styles.primaryContent}>
            Image of a Child (IOAC) is dedicated to revolutionizing early
            childhood education with a unique, research-oriented approach that
            blends traditional philosophies with cutting-edge technologies. Our
            mission is to provide a nurturing and stimulating environment where
            children can explore, learn, and grow into well-rounded individuals
            prepared for the future.
          </div>
        )}
      </div>
      {children}
    </>
  );
};

export default EarleyYears;
