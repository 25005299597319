import React from "react";
import style from "./scss/footer.module.scss";
import logo from "assets/images/IOCLogo.webp";
import footerMap from "assets/images/footerMap.webp";
import fb from "assets/images/fb.webp";
import insta from "assets/images/insta.webp";
import linkedIn from "assets/images/linkedin.webp";
import { Image, Grid } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const Footer = () => {
  let history = useHistory();
  const handleRedirection = (path) => {
    history.push(`/${path}`);
  };
  return (
    <>
      <Grid className={style.footer}>
        <Grid.Column
          computer={5}
          tablet={10}
          mobile={16}
          className={style.footerColumn}
          textAlign="center"
        >
          <div className={style.footerDiv1}>
            <div className={style.IOAC}>
              <div className={style.ioacContent}>
                <p>IOAC</p>
              </div>
              <div className={style.ioacImage}>
                <Image className={style.logoImg} src={logo} alt="logo" />
              </div>
            </div>
            <div className={style.part2}>
              <div className={style.ioacContent2}>
                <p>Write to us @</p>
              </div>
              <div className={style.ioacLink}>
                <p>contact@imageofachild.ai</p>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={5}
          mobile={16}
          tablet={5}
          className={style.footerColumn}
          textAlign="left"
        >
          <div className={style.footerDiv2}>
            <div className={style.contact}>CONTACT</div>
            <div className={style.contactDetails}>
              <div>
                <p className={style.contactContent}>+91 9363614106</p>
              </div>
              <div>
                <p className={style.contactContent}>contact@imageofachild.ai</p>
              </div>
            </div>
            <div className={style.clr15}></div>
            <div className={style.socialMedia}>SOCIAL MEDIA</div>
            <div className={style.socialMediaLogo}>
              <div className={style.logo}>
                <a
                  href="https://www.facebook.com/profile.php?id=61561982102311"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <Image src={fb} alt="fb" />
                </a>
              </div>
              <div className={style.logo}>
                <a
                  href="https://www.instagram.com/p/C9hqUnXJvD_/?igsh=MTU0dHV1anA4azc4YQ=="
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <Image src={insta} alt="insta" />
                </a>
              </div>
              <div className={style.logo}>
                <a
                  href="https://www.linkedin.com/company/image-of-a-child/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <Image src={linkedIn} alt="linkedIn" />
                </a>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16} tablet={16}>
          <div className={style.footerDiv3}>
            <div className={style.visitUs}>
              VISIT US
              <p className={style.visitContent}>
                No. 362/7, Third Floor, 16th Main, 4th T Block East, Jayanagar,
                Bengaluru, Karnataka 560041
              </p>
            </div>
            <div>
              <picture>
                <Image
                  className={style.footerMap}
                  size="small"
                  src={footerMap}
                  alt="map"
                />
              </picture>
            </div>
          </div>
        </Grid.Column>
      </Grid>
      <div className={style.footerMessage}>
        <span>Copyrights reserved</span>|
        <span
          className={style.menu}
          onClickCapture={() => handleRedirection("privacyPolicy")}
        >
          Privacy policy
        </span>
        |
        <span
          className={style.menu}
          onClickCapture={() => handleRedirection("termsAndConditions")}
        >
          Terms & Conditions
        </span>
      </div>
    </>
  );
};

export default Footer;
